import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AccountModule { 
  public ACC_ID: number;
  public ACC_AR_NAME: string;
}
