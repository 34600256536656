import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class EntryModule {
  public Setting_ID: any;
  public Setting_AR_NAME: string;
 }
