import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

readonly rooturl='http://benhamranadminpanelapi.gmtcc.co/api';
readonly pathUrl = "https://adminpanelreport.gmtcc.co/";
readonly reportUrl = "http://adminpanelreport.gmtcc.co/";

// readonly rooturl='http://localhost:63103/api';
// readonly pathUrl = "http://localhost:63103/";
// readonly reportUrl = "https://localhost:44347/";

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    
    this.messageSource.next(message)
  }

}
